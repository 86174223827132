export const firebase_api_key = 'AIzaSyC1G0bKNV5Vj4X0SQ7xhom5o-bMN9S_TxA';  //dev
export const firebase_database_base_url = 'https://flipr-connect-1.firebaseio.com/' //dev


// export const firebase_api_key = 'AIzaSyAdnFJbdHYhkmQz5Hfzg_xFMQmw4p7DQH0';  //stage
// export const firebase_database_base_url = 'https://flipr-connect-12.firebaseio.com/' //stage



// export const firebase_api_key = 'AIzaSyAXROdbUzq-fk-Th-kvzlE3FTr5sVZyoQg ';  //prod
// export const firebase_database_base_url = 'https://flipr-connect-prod.firebaseio.com/' //prod